import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { func } from "../../../Utilities/logFunc";
import { userProfileList, followUser } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import Pagination from "../../Common/Pagination";
import { useTranslation } from "react-i18next";
import OtherUserProfileModal from "../../Profile/OtherUserProfile";

const AllTopProfiles = () => {
  let limit = 12
  let [userProfileData, setUserProfileData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  // let [limit, setLimit] = useState(12);
  let [count, setCount] = useState(1);
  let [totalRecord, setTotalRecord] = useState(1);
  const [skipCount, setSkipCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {t} = useTranslation()
  const [totalPages, setTotalPages] = useState(0);
  const [otherProfileModal, setOtherProfileModal] = useState(false);
  const [otherProfileUserId, setOtherProfileUserId] = useState(null);

  const fetchTopProfiles = async (skipCount) => {
    setLoading(true);
    // console.log("called")
    try {
      let data = {
        skip: skipCount,
        limit: limit,
        type: ["appUser"],
        // sort: "desc",
        sortBy: "followers",
        action: "topProfile",
      };

      const res = await userProfileList(data);

      if (res?.data?.list) {
        setUserProfileData(res?.data?.list);
        setTotalRecord(res?.data?.count);
        setTotalPages(Math.ceil(res?.data?.count/limit))
        setLoading(false);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  //   setSkipCount((prev) => (page - 1) * 12);
  //   fetchTopProfiles(Math.ceil(page - 1) * 12);
  // };

  const pageHandler = (page)=>{
    setCurrentPage(page);
      setSkipCount((prev) => (page - 1) * limit);
      fetchTopProfiles(Math.ceil(page - 1) * limit);
  }

  useEffect(() => {
    fetchTopProfiles(0);
  }, []);

  const loadMoreBtnClick = () => {
    let totalCount = parseInt(count) + 1;
    setCount(totalCount);
    fetchTopProfiles(totalCount);
  };

  const userFollow = async (item) => {
    setLoading(true);
    let data;
    if (item?.isFollow) {
      data = {
        action: "unfollow",
        followingUser: item?._id,
      };
    } else {
      data = {
        action: "follow",
        followingUser: item?._id,
      };
    }

    try {
      const res = await followUser(data);
      if (res?.data?.success) {
        fetchTopProfiles(count);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  return (
    <>
      {
        userProfileData?.length > 0 && (
          <div class="col-12 pagination-height" style={{position:'relative', paddingBottom:'40px'}}>
            <div class="mb-3 d-flex align-items-center justify-content-between">
              <h2 class="h3 bodyBoldFont mb-0">{t('woe.top_profiles')}</h2>
            </div>
            <div class="row g-20">
              {userProfileData.length > 0 &&
                userProfileData.map((data, indp) => (
                  <div class="col-lg-3 col-4" key={data?._id}>
                    <div class="card topProfileCard" onClick={(e) => {
                          setOtherProfileUserId({
                            user_id: data?._id,
                            user: data?.user_id
                          })
                          setOtherProfileModal(true)
                        }
                      }>
                      <div class="card-body text-center">
                        <div class="card-img mb-3">
                          <img
                            src="/assets/images/profile-icon-medium.png"
                            alt=""
                          />
                        </div>
                        <div class="card-title">
                          <h6 class="mb-1">{data?.gamerjiName}</h6>
                          <h6 class="small mb-3">
                            {data?.followers || 0} {t('woe.followers')}
                          </h6>
                        </div>
                        {/* {console.log(JSON.parse(localStorage.getItem('profile'))?.item?._id,data?._id )} */}
                        <button
                          class="btn btn-secondary btn-sm following-btn"
                          onClick={() => userFollow(data)}
                          disabled = {data?._id == JSON.parse(localStorage.getItem('profile'))?.item?._id}
                        >
                          {data?.isFollow ?  t('woe.following') : t('woe.follow')}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

              {/* {userProfileData.length < totalRecord && (
                <div class="card-footer d-flex justify-content-center mt-4">
                  <button
                    class="btn btn-primary col-2 text-center"
                    onClick={() => loadMoreBtnClick()}
                  >
                    Load More
                  </button>
                </div>
              )} */}
              
            </div>
           
          </div>
        )
      }
      {totalPages > 1 && (
        <Pagination   currentPage = {currentPage} totalPages = {totalPages} onPageChange={pageHandler} />
      )}
      {otherProfileModal && (
        <OtherUserProfileModal otherProfileModal={otherProfileModal} setOtherProfileModal={setOtherProfileModal} otherProfileUserId={otherProfileUserId} />
      )}
      {isLoading    ? <Loader />:<></>}
    </>
  );
};

export default AllTopProfiles;
