import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { func } from "../../../Utilities/logFunc";
import { liveStreamingsList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Pagination from "../../Common/Pagination";
import { toast } from "react-toastify"; 
import { useTranslation } from "react-i18next";
import CompanyIcon from '../../../assets/new-images/User-Avatar.png'

import Logo from '../../../assets/images/logo.svg'
const AllPopularVideos = () => {
  let [videoData, setVideoData] = useState([]);
  let [limit, setLimit] = useState(12);
  let [count, setCount] = useState(1);
  let [totalRecord, setTotalRecord] = useState(1);
  const [skipCount, setSkipCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {t} = useTranslation()
  const [totalPages, setTotalPages] = useState(0);
  const fetchLiveStreamingsList = async (skipCount) => {
    try {
      let data = {
        skip: skipCount,
        limit: 8,
        filter: {
          isLive: false,
        },
      };

      const res = await liveStreamingsList(data);
      func("response 27",res);
      if (res?.data?.list) {
        setVideoData(res?.data?.list);
        setTotalRecord(res?.data?.count);
        setTotalPages(Math.ceil(res?.data?.count/8))
      }
      
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg)
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSkipCount((prev) => (page - 1) * 12);
    fetchLiveStreamingsList((page - 1) * 12);
  };

  useEffect(() => {
    fetchLiveStreamingsList(0);
  }, []);

  const loadMoreBtnClick = () => {
    let totalCount = parseInt(count) + 1;
    setCount(totalCount);
    fetchLiveStreamingsList(totalCount);
  };

  const videoUrls = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    let videoId = match && match[2].length === 11 ? match[2] : null;

    return "//www.youtube.com/embed/" + videoId;
  };

  return (
    <>
    {func("video data 66", videoData.length)}
      {videoData?.length > 0 && (
        <div class="col-12 pagination-height" style={{position:'relative', paddingBottom:'40px'}}>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <h2 class="h3 bodyBoldFont mb-0">{t('woe.popular_videos')}</h2>
          </div>
          <div class="row g-20">
            {videoData.length > 0 &&
              videoData.map((data, indp) => (
                <div class="col-lg-3 col-4 col-sm-2">
                  <div class="card popularVideoCard p-md-4">
                    <div class="card-body">
                      <div class="card-img position-relative">
                        <div class="card-video">
                          <iframe
                            class="rounded10"
                            width="100%"
                            src={videoUrls(data?.url)}
                            title={data?.name}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          ></iframe>
                        </div>
                      </div>
                      <div class="card-content mt-2">
                        <div class="d-flex align-items-center">
                          <img
                          style={{objectFit:'contain'}}
                            class="img-thumbnail-36"
                            src={data?.logo?.default ? url.imageUrl +data?.logo?.default :   CompanyIcon}
                            alt=""
                          />
                          <div class="info ms-2 ps-1">
                            <h6 class="mb-1 bodyBoldFont fs-4">{data?.name.length > 25 ? <marquee>{data?.name}</marquee> : data?.name}</h6>
                            {/* <h6 class="mb-0 fs-6 bodyNormalFont text-body">
                            D11 Gaming
                            </h6> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            
          </div>
      
        </div>
      )}
      {totalPages > 1 && (
        // <div class="card-footer d-flex justify-content-center mt-4">
        //   <button
        //     class="btn btn-primary col-2 text-center"
        //     onClick={() => loadMoreBtnClick()}
        //   >
        //     Load More
        //   </button>
        // </div>
        <Pagination currentPage = {currentPage} totalPages = {totalPages} onPageChange={handlePageChange} />
      )}
    </>
  );
};

export default AllPopularVideos;
