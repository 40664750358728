import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { func } from "../../../Utilities/logFunc";
import {
  addContestRate,
  getContestDetails,
  getContestDetailsInfo,
  getContestRate,
  getContestUserList,
} from "../../../Utilities/controller";
import moment from "moment";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import Sad from "../../../assets/images/sad-icon-svg.svg";
import NotGood from "../../../assets/images/notGood-icon-svg.svg";
import Ok from "../../../assets/images/ok-icon-svg.svg";
import Happy from "../../../assets/images/happy-icon-svg.svg";
import VeryHappy from "../../../assets/images/veryHappy-icon-svg.svg";
import UserName from "../../../assets/new-images/gamer.svg";
import Rank from "../../../assets/new-images/podium.svg";
import Kills from "../../../assets/new-images/gun.svg";
import DownArrow from "../../../assets/images/downArrow-svg.svg";
import FriendsShare from "../PrivateContest/FriendsShare";
import WinningBreakUp from "../Tournament/WinningBreakUp";
import CopyIcon from "../../../assets/images/copy-icon-svg.svg";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import Champion from '../../../assets/images/championIcon.png';
import Master from '../../../assets/images/GoldPremium.png';
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import OtherUserProfileModal from "../../Profile/OtherUserProfile";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
const ContestDetailsInfo = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  // var user_data = JSON.parse(localStorage.getItem("userData"));
  const { profileData, updateProfileData } = useProfileDataContext()

  const [contestDetailData, setContestDetailData] = useState([]);
  const [contestCaptainData, setContestCaptianData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [eventRate, setEventRate] = useState(null);
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const remainingPlayers =
    contestDetailData?.totalPlayers - contestDetailData?.totalJoinedPlayers;
  const [otherProfileModal, setOtherProfileModal] = useState(false);
const [otherProfileUserId, setOtherProfileUserId] = useState(null);

  useEffect(() => {
    // fetchContestDetails();
    fetchContestDetailsInfo();
    // fetchContestUserData();
    fetchContestRate();
  }, []);

  const fetchContestDetails = async () => {
    setLoading(true);
    try {
      const res = await getContestDetails(id);
      if (res?.data?.success) {
        setContestDetailData(res?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      func("fetchContestDetails error", error?.response);
      setLoading(false);
    }
  };

  const fetchContestDetailsInfo = async () => {
    setLoading(true);
    try {
      const payload = {
        filter: {
          user: profileData?.item?.user_id,
        },
      }
      const res = await getContestDetailsInfo(payload, id);
      if (res?.data?.success) {
        setContestDetailData(res?.data?.item);
        setContestCaptianData(res?.data?.captainInfo);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      func("fetchContestDetails error", error?.response);
      setLoading(false);
    }
  };

  const fetchContestUserData = async () => {
    setLoading(true);
    try {
      const payload = {
        pagination: {
          pageNo: 1,
          recordPerPage: 10,
          sortBy: "rank",
          sortDirection: "asc",
        },
        search: {
          user: profileData?.item?.user_id,
        },
      };
      const res = await getContestUserList(id, payload);
      if (res?.data?.success) {
        setContestCaptianData(res?.data?.captainData);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      func("fetchContestUserData error", error?.response);
      setLoading(false);
    }
  };

  const fetchContestRate = async () => {
    try {
      let data = {
        contest: id,
      };
      const res = await getContestRate(data, id);
      setEventRate(res?.data?.data);
    } catch (error) {
      func("fetchContestRate err", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const rating = async (rate) => {
    try {
      let data = {
        rate: rate,
        contest: id,
      };
      const res = await addContestRate(data);
      toast.success(t('success_rating_successfully'))
      eventTracking(events.Contest_Ratings, {
        GJ_GameName: contestDetailData.gameAry.name,
        GJ_GameID: contestDetailData.gameAry._id,
        GJ_ContestID: contestDetailData._id,
        GJ_GameTypeID:contestDetailData.gameTypeAry._id,
        GJ_Ratings:rate
      });
      
      fetchContestRate();
      func("rating", res);
    } catch (error) {
      func("rating err", error?.response?.data?.errors?.[0]?.msg);
    }
  };
  const handlerWinning = (data) => {
    setSelectedTournament(contestDetailData);
    eventTracking(events.Contest_winners, {
        GJ_GameName: localStorage.getItem("activeGame"),
      GJ_GameID: localStorage.getItem("activeGameId"),
      GJ_ContestID: contestDetailData._id,
      GJ_GameTypeID:contestDetailData.gameTypeAry._id,
    });
    setShowWinningModal((prev) => true);
  };
  return (
    <>
      {" "}
      {  (
        <div class="col-lg-5 pe-lg-5">
          <div class="title">
            <h3 class="bodyBoldFont mb-3">{t("contest_details.contest_details")}</h3>
          </div>
          <div class="card tournametParticipationCard tournamentSoloCard contestTournamentSoloCard">
            <div class="card-header">
              <h4 class="card-title mb-0">{contestDetailData?.title}</h4>
              <span  class="rulesLink" onClick={() => {
                      navigator.clipboard.writeText(contestDetailData?.shortCode);
                      toast.info(t("info_Copied"), {
                        // position: "top-left",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }}>
                {t("contest_details.id")}: {contestDetailData?.shortCode}
              </span>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="card-content">
                    <div class="row justify-content-between py-2 bg-accent-color-2 color-primary-dark-blue contestWarningDiv">
                      <div class={` ${contestDetailData?.titles?.[1]?.name && contestDetailData?.titles?.[1]?.isSelection && contestDetailData?.titles?.[1]?.value ? 'col-3' : contestDetailData?.titles?.[0]?.name && contestDetailData?.titles?.[0]?.isSelection && contestDetailData?.titles?.[0]?.value ? 'col-4' : 'col-6'}`}>
                        <label for="" class="d-block h6">
                        {t("contest_details.date")}
                        </label>
                        <h6 class="bodyBoldFont mb-0">
                          {moment(contestDetailData?.date).format("DD/MM/YYYY")}
                        </h6>
                      </div>
                      <div class={` ${contestDetailData?.titles?.[1]?.name && contestDetailData?.titles?.[1]?.isSelection && contestDetailData?.titles?.[1]?.value ? 'dividersX blue col-3' : contestDetailData?.titles?.[0]?.name && contestDetailData?.titles?.[0]?.isSelection && contestDetailData?.titles?.[0]?.value ? 'dividersX blue col-4' : 'dividersX blue col-6'}`}>
                        <label for="" class="d-block h6">
                        {t("contest_details.time")}
                        </label>
                        <h6 class="bodyBoldFont mb-0">
                          {moment(contestDetailData?.time).format("h:mm A")}
                        </h6>
                      </div>
                      {contestDetailData?.titles?.[0]?.isSelection && contestDetailData?.titles?.[0]?.name && contestDetailData?.titles?.[0]?.value && 
                        <div class={`${contestDetailData?.titles?.[1]?.name && contestDetailData?.titles?.[1]?.isSelection && contestDetailData?.titles?.[1]?.value ? 'col-3 dividersRight' : 'col-4'}`}>
                          <label for="" class="d-block h6 text-ellipsis_contest" >
                            {contestDetailData?.titles?.[0]?.name}
                          </label>
                          <h6 class="bodyBoldFont mb-0 text-ellipsis_contest" >
                            {contestDetailData?.titles?.[0]?.value}
                          </h6>
                        </div>
                      }
                      {contestDetailData?.titles?.[1]?.isSelection && contestDetailData?.titles?.[1]?.name && contestDetailData?.titles?.[1]?.value && 
                        <div class="col-3">
                          <label for="" class="d-block h6 text-ellipsis_contest" >
                            {contestDetailData?.titles?.[1]?.name}
                          </label>
                          <h6 class="bodyBoldFont mb-0 text-ellipsis_contest" s>
                            {" "}
                            {contestDetailData?.titles?.[1]?.value}
                          </h6>
                        </div>
                      }
                    </div>
                    <div class="row justify-content-between py-2 mt-1">
                      <div class="col-4">
                        <label for="" class="d-block h6">
                        {t("contest_details.prize_pool")}
                        </label>
                        <div class="d-flex align-items-center poolPrizeBox">
                          {
                            // contestDetailData?.prizePoolType === 'amount' && 
                            <span class="icon me-2">
                              <img
                              className="icon-20"
                                src={
                                  url?.imageUrl +
                                  contestDetailData?.currency?.[0]?.outCurrency
                                    ?.img?.default
                                }
                                alt=""
                              />
                            </span>
                          }
                          <h6 class="bodyBoldFont mb-0">
                          {/* {contestDetailData?.prizePoolType === 'amount' ? contestDetailData?.prizePool : contestDetailData?.rewardDisplayText} */}
                          {contestDetailData?.prizePool}
                          </h6>
                        </div>
                      </div>
                      {contestDetailData?.winningModel !== "perRank" ? (
                        <div class="col-4 dividersX d-flex">
                          <div class="">
                            <label for="" class="d-block h6">
                            {t("contest.per_kill")}
                            </label>
                            <div class="d-flex align-items-center poolPrizeBox">
                              <span class="icon me-2">
                                {contestDetailData?.currency?.[0]?.outCurrency
                                  ?.code == "INR" ? (
                                  "₹"
                                ) : (
                                  <img
                                    src={
                                      url.imageUrl +
                                      contestDetailData?.currency?.[0]
                                        ?.outCurrency?.img?.default
                                    }
                                    alt=""
                                  />
                                )}
                              </span>
                              <h6 class="bodyBoldFont mb-0">
                                {" "}
                                {contestDetailData?.killPoints}
                              </h6>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="col-4 dividersX"
                          onClick={handlerWinning}
                        >
                          <label for="" className="d-block h6">
                          {t("tournaments.winners")}
                          </label>

                          <div className="d-flex align-items-center poolPrizeBox">
                            <h5 className="bodyBoldFont mb-0 ">
                              {contestDetailData?.totalWinners}
                            </h5>
                            {/* <span className="icon me-2">
                              <img src={DownArrow} alt="" />
                            </span> */}
                             <i className="fas fa-chevron-down mx-2"></i>
                          </div>
                         
                        </div>
                      )}
                      <div class="col-4 d-flex">
                        <div 
                        // class="ms-auto"
                        >
                          <label for="" class="d-block h6">
                          {t("contest.join_using")}
                          </label>
                          <div class="d-flex align-items-center useCoinBox">
                            <span class="icon me-2">
                              {
                                contestDetailData?.entryFee > 0 && 
                                <img
                                src={
                                  url?.imageUrl +
                                  contestDetailData?.currency?.[0]?.inCurrency
                                    ?.img?.default
                                }
                                alt=""
                              />
                              }
                              
                            </span>
                            <h6 class="bodyBoldFont mb-0">
                              {contestDetailData?.entryFee > 0 ? contestDetailData?.entryFee :  t("contest_details.free")}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div
                          class="progress"
                          role="progressbar"
                          aria-label="Basic example"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <div
                            class="progress-bar bg-accent-color-2"
                            style={{
                              width: `${
                                (100 * contestDetailData?.totalJoinedPlayers) /
                                contestDetailData?.totalPlayers
                              }%`,
                            }}
                          ></div>
                        </div>
                        <div class="d-flex justify-content-between mt-2">
                          <h5>{t("contest_details.players_remaining", {
                              players: remainingPlayers,
                            })}</h5>
                          <h5>
                          {t("contest_details.players_joined", {
                              players: contestDetailData?.totalJoinedPlayers,
                            })}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-white">
              <div class="bg-primary-color-red pt-2 pb-1 box">
                <div class="row justify-content-center py-2 mt-1">
                  <div class="col-4 text-center" style={
                      document.documentElement.dir == "rtl"
                        ? { borderLeft: "1px solid #fff" }
                        : { borderRight: "1px solid #fff" }
                    }>
                    <label for="" class="d-block h6 mb-0">
                    {t("contest.room_id")}
                    </label>
                    <h6 class="bodyBoldFont mb-0">
                      {contestDetailData?.roomId === ""
                        ? "-"
                        : contestDetailData?.roomId?.length > 25 ? (
                          <marquee>{contestDetailData?.roomId}</marquee>
                          ) : (
                            contestDetailData?.roomId
                          )}
                          {contestDetailData?.roomId ? (
                                <span class="icon ms-2" onclick="copyText();">
                                  <img
                               
                                    src="/assets/images/copy-icon-svg.svg"
                                    alt=""
                                    className="cursor icon-18"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        contestDetailData?.roomId
                                      );
                                      eventTracking(events.Contest_Room_ID, {
                                        GJ_GameName: contestDetailData.gameAry.name, GJ_GameID: contestDetailData.gameAry._id,
                                        GJ_GameTypeID:contestDetailData.gameTypeAry._id, GJ_ContestID:contestDetailData.gameTypeAry._id,
                                      });
                                      toast.info(t("info_Copied"), {
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    }}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                    </h6>
                  </div>
                  <div class="col-4 text-center px-4">
                    <label for="" class="d-block h6 mb-0">
                    {t("contest.password")}
                    </label>
                    <h6 class="bodyBoldFont mb-0">
                      {contestDetailData?.roomPassword === ""
                        ? "-"
                        : contestDetailData?.roomPassword?.length > 25 ? (
                          <marquee>{contestDetailData?.roomPassword}</marquee>
                          ) : (
                            contestDetailData?.roomPassword
                          )}
                         {contestDetailData?.roomPassword ? (
                                <span class="icon ms-2" onclick="copyText();">
                                  <img
                                    src="/assets/images/copy-icon-svg.svg"
                                    alt=""
                                    className="cursor icon-18"
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        contestDetailData?.roomPassword
                                      );
                                      eventTracking(events.Contest_Passsword, {
                                        GJ_GameName: contestDetailData.gameAry.name, GJ_GameID: contestDetailData.gameAry._id,
                                        GJ_GameTypeID:contestDetailData.gameTypeAry._id, GJ_ContestID:contestDetailData.gameTypeAry._id,
                                      });
                                      toast.info(t("info_Copied"), {
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    }}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card userProfileCard mt-5">
            <div class="card-header">
              <div class="row justify-content-between align-items-center">
                <div class="col-lg-5 col-4 d-flex">
                  <div class="text-center">
                    <span class="icon mb-2 d-block">
                      <img className="icon-30" src={UserName} alt="" />
                    </span>
                    <h5 class="bodyNormalFont mb-0 color-primary-dark-blue">{t("contest_details.gamerji_username")}</h5>
                  </div>
                </div>
                <div class="col-lg-6 col-8">
                  <div class="d-flex justify-content-between">
                    <div class="col text-center dividersRightBlue">
                      <span class="icon mb-2 d-block">
                        <img className="icon-30" src={Kills} alt="" />
                      </span>
                      <h5 class="bodyNormalFont mb-0 color-primary-dark-blue">{t("contest_details.kills")}</h5>
                    </div>
                    <div class="col text-center">
                      <span class="icon mb-2 d-block">
                        <img className="icon-30" src={Rank} alt="" />
                      </span>
                      <h5 class="bodyNormalFont mb-0 color-primary-dark-blue"> {t("contest_details.rank")}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-between align-items-center">
                <div class="col-lg-5 col-4">
                  <div class="d-flex align-items-center" onClick={(e) => {
                          setOtherProfileUserId({
                            user_id: contestCaptainData?._id,
                            user: contestCaptainData?.user_id
                          })
                          setOtherProfileModal(true)
                        }
                      }>
                    <span class="icon me-2 d-block">
                      <img
                        class="icon-50"
                        src={url.imageUrl +contestCaptainData?.level?.featuredImage?.default}
                        alt=""
                      />
                    </span>
                    <div className="d-flex justify-content-between align-items-center w-100" >
                    <h5 class="bodyNormalFont mb-0" style={{display:'block',width:'10rem',whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>
                        {contestCaptainData?.gamerjiName}
                    </h5>
                    {
                      contestCaptainData?.isPremium ? 
                      // <img className="icon-premium ms-1" src={localStorage.getItem("planName") == "Champion" ? Champion : Master} /> :""
                      <img className="icon-premium ms-1" src={Master}  alt="Master" /> :""
                    }
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-8">
                  <div class="d-flex justify-content-between">
                    <div class="col text-center">
                      <h5 class="bodyNormalFont mb-0">
                        {contestCaptainData?.kills > 0
                          ? contestCaptainData?.kills
                          : "-"}
                      </h5>
                    </div>
                    <div class="col text-center">
                      <h5 class="bodyNormalFont mb-0">
                        {contestCaptainData?.rank > 0
                          ? contestCaptainData?.rank
                          : "-"}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FriendsShare code ={contestDetailData?.shortCode} onEvent={() => {
                    eventTracking(events.Share_With_Friends, {
                      GJ_GameName: contestDetailData.gameAry.name, GJ_GameID: contestDetailData.gameAry._id,
                      GJ_GameTypeID:contestDetailData.gameTypeAry._id, GJ_ContestID:contestDetailData.gameTypeAry._id,
                    });
                  }}/>
          <div class="card feedbackCard mt-4">
            <div class="card-title text-center mb-5">
              <h4 class="m-0"> {eventRate?.rate
                  ? t("contest_details.thanks_for_the_rating")
                  : t("contest_details.rate_gamerji")}</h4>
            </div>
            <ul class="d-flex align-items-center justify-content-center list-unstyled m-0">
              {eventRate?.rate ? (
                eventRate?.rate === 1 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={Sad} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img className="icon-50" src={Sad} alt="" onClick={() => rating(1)} />
                  </span>
                </li>
              )}

              {eventRate?.rate ? (
                eventRate?.rate === 2 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={NotGood} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img className="icon-50" src={NotGood} alt="" onClick={() => rating(2)} />
                  </span>
                </li>
              )}

              {eventRate?.rate ? (
                eventRate?.rate === 3 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={Ok} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img className="icon-50" src={Ok} onClick={() => rating(3)} />
                  </span>
                </li>
              )}

              {eventRate?.rate ? (
                eventRate?.rate === 4 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={Happy} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img className="icon-50" src={Happy} alt="" onClick={() => rating(4)} />
                  </span>
                </li>
              )}

              {eventRate?.rate ? (
                eventRate?.rate === 5 ? (
                  <li class="mx-2">
                    <img className="icon-50" src={VeryHappy} alt="" />
                  </li>
                ) : (
                  <></>
                )
              ) : (
                <li class="mx-2">
                  <span>
                    <img className="icon-50" src={VeryHappy} alt="" onClick={() => rating(5)} />
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
          selectedTournament={selectedTournament}
        />
      )}
      {otherProfileModal && (
        <OtherUserProfileModal otherProfileModal={otherProfileModal} setOtherProfileModal={setOtherProfileModal} otherProfileUserId={otherProfileUserId} />
      )}

{isLoading ?  <Loader /> : <></>}
    </>
  );
};

export default ContestDetailsInfo;
