// firebaseAnalytics.js
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD0c4CFQlzI9gXgwmSix50cs68YfSF_rqE",
  authDomain: "gamerji-web-portal.firebaseapp.com",
  projectId: "gamerji-web-portal",
  storageBucket: "gamerji-web-portal.appspot.com",
  messagingSenderId: "10243302579",
  appId: "1:10243302579:web:1bb9cea851319007f6bcc6",
  measurementId: "G-NLLHVQ40JD",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

//log event
const eventTracking = (event, parameters) => {
  

  var currentDate = new Date().toTimeString();
  var profileData = JSON.parse(localStorage.getItem("profile"));
  console.log(profileData);
  var customParam = { ...parameters, GJ_TimeStamp: currentDate };
  if (profileData != null && profileData?.item?.user_id) {
    customParam.GJ_UserID = profileData?.item?.user_id;
  } else if (profileData != null && profileData?.item?._id) {
    customParam.GJ_UserID = profileData?.item?._id;
  }
  //console.log("Event Triggered", event, customParam);
  logEvent(analytics, event, customParam);
};

export { analytics, logEvent, eventTracking };
