import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router';
import { routes } from '../../../Utilities/routesFb';
import { url } from '../../../Utilities/url';
import { func } from '../../../Utilities/logFunc';
import { createScrim, getHeaderApi } from '../../../Utilities/controller';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useProfileDataContext } from '../ProfileContext';
import Loader from '../../Common/Loader';
import { Trans, useTranslation } from "react-i18next";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
const SummaryContest = ({ showSummary, setShowSummary, data, currName, currImg, currencyId }) => {
  func("data", currName)
  const [show, setShow] = useState(showSummary);
  const { t } = useTranslation()

  const [currBalance, setCurrBalance] = useState('')
  const [dateTime, setDateTime] = useState('')
  // const premiumUser = JSON.parse(localStorage.getItem("premiumUser"))
  const [loading, setloading] = useState(false)
  const { profileData, updateProfileData ,premiumUser} = useProfileDataContext()

  const navigate = useNavigate()
  const handleClose = () => {
    setShowSummary(prev => false)
    setShow(false)
  }
  const handlerCreateContest = async () => {
    // var newTime = data["time"]?.split(" ");
    // console.log(newTime, newTime[0], newTime[1], newTime[2]);
    // var temp = newTime[0].split(":");
    // if (newTime[1] == "PM" && temp[0] != 12) {
    //   temp[0] = parseInt(temp[0]) + 12;
    //   newTime[0] = temp[0].toString() + ":" + temp[1];
    // }

    //   newTime = newTime[0]  + ":00"
    //   console.log(newTime)
    //   var newDate = data['date'] + " " + newTime 
    //   // var newDate = data.payload['date'] + " " + newTime + ":00"
    //   const [dateString, timeString] = newDate.split(' ')
    //   const [day, month, year] = dateString.split('/')
    //   const [hour, minute, second] = timeString.split(':')
    //   const dateObj = new Date(+year, +month - 1, +day, +hour, +minute, +second)
    //     console.log(dateObj.toISOString())
    //         console.log( dateObj)
    //   console.log("new date=>",newDate);
    //   var datee = year+"-"+month+"-"+day+" "+hour+":"+minute+":"+second
    //   console.log(datee,new Date(datee))

    data = { ...data, platform: "webapp" }
    try {
      setloading(true)
      const res = await createScrim(data)
      func("id", res?.data?.[0].data._id)
      if (res?.success) {
        eventTracking(events.Create_private_contest_Submit, {
          GJ_GameName: localStorage.getItem('activeGame'),
          GJ_GameID: localStorage.getItem('activeGameId'),
          GJ_PrivateContestID: res?.data?.[0].data._id
        });
        eventTracking(events.Summary_Submit, {
          GJ_GameName: localStorage.getItem('activeGame'),
          GJ_GameID: localStorage.getItem('activeGameId'),
          GJ_PrivateContestID: res?.data?.[0].data._id
        });


        setloading(false)
        const resProfile = await getHeaderApi();
        updateProfileData(resProfile?.data)
        navigate('/home/' + routes.privateContest + '/manage-contest/'+res?.data?.[0].data._id)
        // window.location.href = '/home/' + routes.privateContest + '/manage-contest/'+res?.data?.[0].data._id

      }
    }
    catch (error) {

      setloading(false)
      if (error?.response?.data?.data)
        toast.error(error?.response?.data?.data[0]?.msg)
      if (error?.response?.data?.errors)
        toast.error(error?.response?.data?.errors[0]?.msg)
    }

  }

  useEffect(() => {
    let wallet = profileData?.item?.wallet
    func("wallet", wallet)
    func("cureid", currencyId)
    setDateTime(data?.date + " " + data?.time + ":00")


    for (let i = 0; i < wallet?.length; i++) {
      if (wallet[i].currency === currencyId) {
        func("code", wallet[i].winningAmount)
        setCurrBalance(wallet[i].winningAmount)
      }
    }
  }, [])
  //if(loading) return <Loader />
  return (

    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="contestSummaryModal modal fade"
      centered
    >

      <Modal.Body>
        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
        <div className="row justify-content-center">
          <div className="col-12">
            <h3 className="bodyBoldFont mb-4 pb-3 text-center text-dark">{t('contest_details.summary')}</h3>
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="card summaryCard h-100">
                  <div className="card-body">
                    <h4 className="card-title bodyBoldFont text-primary-color-red ">{t('private_contest.basic_details')}</h4>
                    <div className="card gameInfoCard">
                      <div className="card-body">
                        <h4 className="bodyBoldFont" style={{ marginBottom: '2rem' }}>{data.title}</h4>
                        <div className="row justify-content-between py-2 match-height g-4 bg-dark text-dark">
                          <div className="col mt-2">
                            <label for="" className="d-block h6 bodyNormalFont" >{t('contest_details.date')}</label>
                            <h6 className="mb-0" style={{ fontSize: '0.8rem' }}>{moment(data?.date).format("DD/MM/YYYY")}</h6>
                          </div>
                          <div className="col dividersX mt-2">
                            <label for="" className="d-block h6 bodyNormalFont" >{t('contest_details.time')}</label>
                            <h6 className="mb-0" style={{ fontSize: '0.8rem' }}>{moment(data?.time).format("hh:mm A")}</h6>
                          </div>
                          <div className={`col mt-2 ${data?.title2?.name ? "dividersRight" : ""}`}>
                            <label for="" className="d-block h6 bodyNormalFont">{data?.title1.name}</label>
                            <h6 className="mb-0">{data?.title1?.value}</h6>
                          </div>
                          {func("title2", data?.title2)}
                          {
                            data?.title2?.name ?
                              <div className="col mt-2">
                                <label for="" className="d-block h6 bodyNormalFont">{data?.title2?.name}</label>
                                <h6 className="mb-0">{data?.title2.value}</h6>
                              </div>
                              : <></>
                          }

                        </div>
                        <div className="row justify-content-between pt-4 players">
                          <div className="col-6 dividersRight ">
                            <label for="" className="d-block h6 bodyNormalFont"> {t('contest_details.players')}</label>
                            <h6 className="mb-0">{data?.noOfPlayerCapacity}</h6>
                          </div>
                          <div className="col-6 d-flex justify-content-start">
                            <div>
                              <label for="" className="d-block h6 bodyNormalFont"> {t('tournaments.winners')}</label>
                              <h6 className="mb-0">{data?.noOfWinner}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card summaryCard rulesCard h-100">
                  <div class="card-body">
                    <h4 className="card-title bodyBoldFont text-primary-color-red ">{t('private_contest.game_rules')}</h4>
                    <div className='text-dark' dangerouslySetInnerHTML={{ __html: data?.rules }} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="bg-error text-white p-4 mt-5 d-flex justify-content-center">
                  <div className="col-6 d-flex" style={{ justifyContent: 'space-between' }}>

                    <h4 className="mb-0 " style={{ width: '70%' }}>  {currName == "Diamond" ? t('contest.diamond_balance', { n: currName }) : t('private_contest.coin_balance', { n: currName })}  </h4>
                    <span className="icon ms-auto me-2" style={{ width: '30%', textAlign: 'left' }}>
                      <img
                        src={url.imageUrl + currImg} className="icon-20 me-2" alt="" /> {currBalance || 0}</span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="text-white p-4 mt-2 d-flex justify-content-center">
                  <div className="col-6 d-flex" style={{ justifyContent: 'space-between' }}>
                    <h4 className="mb-0 text-dark" style={{ width: '50%' }}>
                      {/* {currName}  */}
                      {t('private_contest.your_fee')}</h4>
                    <span className="icon ms-auto me-2 text-dark" style={{ width: '30%', textAlign: 'left' }}>
                      {
                        data?.feeAmount > 0 && (!premiumUser || localStorage.getItem("planName") == "Champion") ? <img
                          src={url.imageUrl + currImg} className="icon-20 me-2" alt="" />
                          : ""
                      }
                      {data?.feeAmount > 0 && (!premiumUser || localStorage.getItem("planName") == "Champion") ? data?.feeAmount : t("tournaments.free")}</span>
                  </div>
                </div>
              </div>
              {
                premiumUser && localStorage.getItem("planName") !== "Champion" ? <>
                  <div className="col-12">
                    <div className="text-white p-4 mt-2 d-flex justify-content-center" style={{ backgroundColor: 'var(--primary-color-dark)' }}>
                      <div className="col-6 d-flex" style={{ justifyContent: 'space-between' }}>

                        <h4 className="mb-0 " style={{ width: '50%' }}>{currName} Saved </h4>
                        <span className="icon ms-auto me-2" style={{ width: '30%', textAlign: 'left' }}>
                          {
                            data?.feeAmount > 0 ? <img
                              src={url.imageUrl + currImg} className="icon-20 me-2" alt="" /> : ""

                          }

                          {data?.feeAmount > 0 ? data?.feeAmount : t("tournaments.free") || 0}</span>
                      </div>
                    </div>
                  </div>
                </>
                  :
                  <h6 className="text-center d11-premium-h6 text-dark">
                    {/* Subscribe to{" "}
                  <Link to={"/home/" + routes.getSubscriptionTypeList}>
                    D11 Premium
                  </Link>{" "}
                  to host unlimited contests for free */}
                    {/* {t('private_contest.subsc_premium',{ 0:<Link to={"/home/" + routes.getSubscriptionTypeList}>Mobily Premium</Link>})} */}
                    <Trans
                      i18nKey="private_contest.subsc_premium"
                      t={t}
                      components={[<Link className="link" to={"/home/" + routes.getSubscriptionTypeList}> {t('premium.gamerji_premium')} </Link>]}
                    />
                  </h6>}

              <div className="col-lg-6 mt-3">
                <div className="d-grid">
                  <span className="btn btn-primary btn-arrow" onClick={handlerCreateContest}>{t('private_contest.create_contest')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading ? <Loader /> : <></>}
      </Modal.Body>
    </Modal>
  )
}

export default SummaryContest