import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { url } from '../../Utilities/url';
import { func } from '../../Utilities/logFunc';
import { errors } from '../../Utilities/error';
import Error from '../Common/Error';
import { useProfileDataContext } from '../HomePage/ProfileContext';
import { useTranslation } from 'react-i18next';

const CallBackMobileNumber = ({mobileNo,setMobileNo,setShowModal,setShowTimeSlot}) => {
  func("cbmn 7", mobileNo)
    const [show, setShow] = useState(true);
    const [error, setError] = useState('')
    const [number, setNumber] = useState(mobileNo)
    const { liteProfile, premiumUser }  =  useProfileDataContext()
  // let premiumUser = JSON.parse(localStorage.getItem("premiumUser"))
    
  const {t} = useTranslation()
  
    const handleClose = () => {
    //   setShowWinningModal((prev) => false);
    setShowModal(false)
      setShow(false);
    };
    const handlerNext = ()=>{
      if(number.length == 0 )
      setError(t('error_enter_phone'))
    else if (number.length < 10)
    setError(t('error_enter_valid_phone'))
    else{
      setMobileNo(number)
      setShow(false)
      setShowModal(false)
      setShowTimeSlot(true)
    }
      
    }
  return (
    <Modal
    show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade "
      centered
    >
        <Modal.Body className={`subscriptionModalPopup ${premiumUser ? "bg-premium text-light": ""}`}>
        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
        <div class="title text-center">
            <h1 class={`h3 mb-4 pb-1 bodyBoldFont text-light`}>
            {t('crm.enter_mobile_number')}
            </h1>
          </div>
          <div class={`row justify-content-center ${premiumUser ? "bg-premium text-light": ""}`}>
            <div class="col-xl-6 col-lg-7 col-md-9 col-12 px-lg-0">
                <div className='row'>
            <div className="col-4">
                        <label
                          for="inputCoutryCode"
                          className="form-label text-nowrap"
                        >
                           {t('crm.country_code')}
                        </label>

                      
                         
                         
                       
                            <div
                              className="d11-menu form-control"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span class="icon">
                                <img
                                  class="icon-16 me-1"
                                  style={{
                                    borderRadius: "50%",
                                    width: "24px",
                                    height: "24px",
                                    marginRight: "5px",
                                  }}
                                  src={url.imageUrl + liteProfile?.country?.flag?.default}
                                  alt=""
                                />
                              </span>{" "}
                              {liteProfile?.country?.dialingCode}
                            </div>
                        
                        
                      </div>
                      <div className="col-8">
                        <label for="inputMobileNumber" className="form-label">
                        {t('crm.mobile_number')}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="inputMobileNumber"
                          placeholder="Enter Mobile Number"
                          name="mobileNumber"
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-","."].includes(e.key) &&
                            e.preventDefault()
                          }
                          onChange={(e) => setNumber(e.target.value)}
                          maxLength={14}
                        
                          value={number.slice(0,14)}
                        />
                         {
                        error && <Error error={error} />
                      }
                      </div>
                      </div>
                      <p className='d11-premium-h6 mt-4 text-center' >{t('crm.you_will_reeceive_a_call_back_on_the_entered_number')}</p>
                     
                      <div class="col-12 mt-4 pt-2">
                        <div class="d-grid">
                          <button class="btn btn-gradient" onClick={handlerNext}>{t('crm.next')}</button>
                        </div>
                      </div>
            </div>
          </div>
        </Modal.Body>
    </Modal>
  )
}

export default CallBackMobileNumber