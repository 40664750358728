import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { createStripeTransaction, getHeaderApi ,payUWebhook,updateXsollaTransaction } from "../../../Utilities/controller";
import "../../../assets/css/PaymentStatus.css";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
// import ProfileDataProvider, { useProfileDataContext } from "../ProfileContext";
// import Pending from "../../assets/images/pending-payment.png";
// import Fail from "../../assets/images/fail-payment.png";
// import Success from "../../assets/images/success-payment.png";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map"; 
import { useProfileDataContext } from "../ProfileContext";

const PaymentStatus = (props) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const [transactionData, setTansactionData] = useState({});
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const {profileData, updateProfileData} = useProfileDataContext()
  const {t} = useTranslation()
  const [message, setMessage] = useState('')
  const handleClose = () => {
    setShow(false);
    navigate('/home')
  };
  const handlerDone = async()=>{
    const resProfile = await getHeaderApi();
    updateProfileData(resProfile?.data)
    setShow(false);
    navigate('/home/coin-store')
  
  }

  const fetchPayUDetails = async() => {
    // console.log("Payu details ***********")
    try{
      const status = searchParams.get('status')
      const txnid = searchParams.get('txnid')
      const message = searchParams.get('message')
      const amount = searchParams.get('amount')
      const paymentTimestamp = searchParams.get('paymentTimestamp')
      // const productId = searchParams.get('productId')
      // const productInfo = searchParams.get('productinfo')
      let payload = {
        status,
            txnid,
            message,
            amount,
            paymentTimestamp,
           
            
      }
      console.log(payload)
      // const res = await payUWebhook(payload)
      // console.log(res)                      
      // if(res?.data?.success){
      //   console.log("success")
      //   setTansactionData(res?.data?.data)
      // }
    }
    catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    setLoading(true);
    console.log(searchParams.get('payment_source'))
    let timer = setTimeout(() => {
      if(searchParams.get('payment_source')=='payu')
        fetchPayUDetails()
      // else
      // fetchXsollaDetails();
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
	}, []);

  // const fetchCoinStoreData = async () => {
	
  //   try {
  //     let body = {
  //       "coinStore": id,
  //       "clientSecret": searchParams.get('payment_intent')
  //     }
  //     const res = await createStripeTransaction(body);
  //     if(res.data.success) {
  //       setTansactionData(res.data.data);
  //     } else {
  //     }
  //   } catch (error) {
  //     // console.log(error)
  //     toast.error(error?.response?.data?.errors?.[0]?.msg)
  //   }

  // }
  // const fetchXsollaDetails = async () => {
	
  //   try {
  //     // console.log('-----------------', profileData?.item)
   
  //     if(profileData?.item?.user_id == searchParams.get('user_id')){

      
  //     let xsollaBody = JSON.parse(localStorage.getItem("xsolla-body"));
  //     console.log(xsollaBody);
  //     let body = {
  //       "transactionUniqueID": searchParams.get('foreignInvoice'),
  //       "coin": xsollaBody.coin,
  //       "sku":  xsollaBody.sku,
  //       "amount": xsollaBody.amount,
  //       "pgData": {
  //           "status": searchParams.get('status'),
  //           "invoice_id":searchParams.get('invoice_id')
  //       }
  //     }
  //     // console.log(body);

  //     const res = await updateXsollaTransaction(body);
  //     // console.log(res)
  //     if(res?.data?.success) {
  //       setTansactionData(res?.data?.data);
  //       // console.log("messgae", res?.data?.message)
  //       setMessage( res?.data?.message)
  //       setLoading(false);
  //     } else {
  //       window.location.href = '/home'
  //     }
  //   }
  //   else{
  //     window.location.href= '/home'
  //   }
  //   } catch (error) {
  //     console.log(error)
  //     setLoading(false);
  //     toast.error(error?.response?.data?.errors?.[0]?.msg)
  //   }

  // }
 
  return (
    <Modal 
    show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
      <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
        <div className="pg_status_main_component">
            {/* <ToastContainer /> */}

            <div className="pg_status_body">
                {/* <div className="pg_status_title_component">
                    <div className="pg_title">Payment Status</div>
                </div> */}
                {console.log(transactionData)}
                <div className="pg_ps_status_component">
                    <div className={
                        transactionData?.paymentStatus === 'SUCCESS' ? "pg_status_background_success"
                            : transactionData?.paymentStatus === 'FAILED' ? 'pg_status_background_failed'
                                : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? 'pg_status_background_failed'
                                    : 'pg_status_background_pending'
                    }>
                        {/* Status Icon */}
                        {/* <img className="pg_status_icon" src={
                            transactionData?.paymentStatus === 'SUCCESS' ? Success
                                : transactionData?.paymentStatus === 'FAILED' ? Fail
                                    : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? Fail
                                        : Pending
                        } /> */}

                        {/* Title */}
                        <div className="pg_status_title">
                            {transactionData?.paymentStatus === 'SUCCESS' ? 'Success!'
                                : transactionData?.paymentStatus === 'FAILED' ? 'Failure!'
                                    : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? 'User Cancelled'
                                        : 'Processing...'}
                        </div>

                      {
                        !loading && <>
                         <div className="pg_status_amount">{getSymbolFromCurrency(localStorage.getItem('currecySymbol'))} {transactionData?.amount}
                         </div>

                      
<div className="pg_status_date">{new Date(transactionData?.paymentTimestamp).toLocaleString()}</div>

<div className="pg_status_transaction">{t('premium.transaction_id')} : {transactionData?.transactionId}</div>
                        </>
                      }
                       
                        {transactionData?.paymentStatus === 'SUCCESS' ?
                            <div className="pg_status_transaction">
                              {console.log("trasads", transactionData)}
                              {message}
                              {/* {t('premium.your_subscription_success',{amount:transactionData.coinAmount})} */}
                              </div>
                            : 
                            transactionData?.paymentStatus === 'FAILED' ?
                            <div className="pg_status_transaction">{t('premium.cant_proceed')}</div>
                            : t('premium.transaction_inprogess')
                        }
                       


                    </div>
                    <button className={`btn btn-primary main-btn next mt-4 mb-4 ml-2 ${transactionData?.paymentStatus!=='SUCCESS' && transactionData?.paymentStatus !== "FAILED" ? "disabled" : ""}`} style={{ width: "100%", border: '1px solid' }} onClick={handlerDone}>{t('premium.done')}</button>
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>
</Modal.Body>
</Modal>
    );
};
export default PaymentStatus;
