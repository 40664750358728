import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import getSymbolFromCurrency from "currency-symbol-map";
import { createPayPayment } from "../../../Utilities/controller";
import Loader from "../../Common/Loader";
import Diamond2 from "../../../assets/images/purchase-pack-icon.png";
import { url } from "../../../Utilities/url";
import { useProfileDataContext } from "./../ProfileContext";
import premiumIcon from '../../../assets/images/GoldPremium.3f302fe4b33781bb34c5.png'
const PayuModal = ({ showPayuForm, setShowPayuForm, payload }) => {
  const { profileData } = useProfileDataContext();

  const [payuURL, setPayuURL] = useState(process.env.PAYU_BASE_URL)
  console.log("*********payload", payload, payuURL)
  const [loading, setLoading] = useState(false)


  const fetch = async () => {
    try {
      const res = await createPayPayment(payload)
      console.log(res)
    }
    catch (error) {
      setLoading(false)
      console.error(error);
    }
  }
  const handleClose = () => {
    setShowPayuForm(false);
    if (setShowPayuForm)
      setShowPayuForm(false)
  };


  return (
    <Modal
      show={showPayuForm}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="authModal_transaction  modal fade "
      id="authModal_transaction"
      centered
    >
      <Modal.Body>
        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
        {/* {console.log("payuURL",payuURL+"")} */}
        <div class="row justify-content-center align-items-center">
          <h2 className="text-black text-center">Confirm Payment</h2>


          <div className="col-12 d-flex align-items-center justify-content-center mt-4">
            <form className="col-6" action=
              'https://secure.payu.in/_payment' //payU URL
              // {payuURL+""}
              method="post">
              <input type="hidden" name="key" value={payload?.key} />
              <input type="hidden" name="txnid" value={payload?.txnid} />
              <input type="hidden" name="productinfo" value={payload?.productInfo} />
              <input type="hidden" name="amount" value={payload?.amount} />
              <input type="hidden" name="email" value={payload?.email || localStorage.getItem('verifiedEmail')?.toString} />
              <input type="hidden" name="udf1" value={payload?.productId} />
              <input type="hidden" name="udf2" value={payload?.userId} />
              <input type="hidden" name="surl" value={payload?.surl} />
              <input type="hidden" name="furl" value={payload?.furl} />
              <input type="hidden" name="curl" value={payload?.curl} />
              <input type="hidden" name="phone" value={payload?.phone} />
              <input type="hidden" name="hash" value={payload?.hash} />

              <div class="col-12 " style={{
                alignContent: "center",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginBottom: "12px"
              }}>
                <div class="card earncoinCard" style={{
                  height: "fit-content"
                }}>
                  <div class="row gap text-center" >
                  {payload?.productInfo===process.env.REACT_APP_COIN_PRODUCT &&
                    <div class="col-12 mt-3 mb-4">
                      <img
                        src={Diamond2}
                        style={{ height: "5.625rem", width: "8.625rem" }}
                        alt=""
                      />
                    </div>
                  }
                  {payload?.productInfo===process.env.REACT_APP_SUBSCRIPTION_PRODUCT &&
                    <div class="col-12 mt-3 mb-4">
                      <img
                        src={premiumIcon}
                        style={{ height: "5.625rem", width: "4.625rem" }}
                        alt=""
                      />
                    </div>
                  }

                  {payload?.productInfo===process.env.REACT_APP_COIN_PRODUCT &&
                    <div class="col-12 mb-4">
                      <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                        {payload?.description ? payload?.description : payload?.coins}
                        <span class="mx-2">
                          {/* <img src={Diamond} className="icon-30"/> */}
                          <img src={url.imageUrl + profileData?.item?.wallet[profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == "coin")]?.currencyData?.img?.default} className="icon-30" alt="Gld" />
                        </span>
                      </h6>
                    </div>
                    }

                    {payload?.productInfo===process.env.REACT_APP_SUBSCRIPTION_PRODUCT &&
                    <div class="col-12 mb-4">
                      <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                        {payload?.packName ? payload?.packName : ""}
                        <br/>
                        <span class="mx-2">
                          {/* <img src={Diamond} className="icon-30"/> */}
                          {/* <img src={url.imageUrl + profileData?.item?.wallet[profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == "coin")]?.currencyData?.img?.default} className="icon-30" alt="Gld" /> */}
                          Subscription Purchase
                        </span>
                      </h6>
                    </div>
                    }



                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="d-grid px-2">
                  <button
                    type="submit"
                    className="btn text-white btn-earncoin d-flex align-items-center justify-content-center"
                    onClick={() => fetch()}
                    style={{ textAlign: "center", top: 0, outline: 'none' }}>Buy {" "}{"₹"}<span className="h4 mb-0 ms-1">{payload?.amount}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PayuModal;
