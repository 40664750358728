import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import {getHeaderApi} from "../../../Utilities/controller";
import "../../../assets/css/PaymentStatus.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import { useProfileDataContext } from "../ProfileContext";

const PayuPaymentStatus = (props) => {


  const navigate = useNavigate();

  let { id } = useParams();
  const { t } = useTranslation()
  const { profileData, updateProfileData } = useProfileDataContext()
  const [searchParams] = useSearchParams();
  const [transactionData, setTansactionData] = useState({});
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('')



  const handleClose = () => {
    setShow(false);
    navigate('/home')
  };

  const handlerDone = async () => {
    const resProfile = await getHeaderApi();
    updateProfileData(resProfile?.data)
    setShow(false);
    navigate('/home/coin-store')

  }

  const fetchPayUDetails = async () => {
    try {
      const paymentStatus = searchParams.get('status')
      const transactionId = searchParams.get('txnid')
      const message = searchParams.get('message')
      const amount = searchParams.get('amount')
      const paymentTimestamp = searchParams.get('paymentTimestamp')
      let payload = {
        paymentStatus,
        transactionId,
        message,
        amount,
        paymentTimestamp,
      }
      setTansactionData(payload)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchPayUDetails()
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              <div className="pg_status_main_component">
                {/* <ToastContainer /> */}
                <div className="pg_status_body">
                  {/* <div className="pg_status_title_component">
                    <div className="pg_title">Payment Status</div>
                </div> */}
                  <div className="pg_ps_status_component">
                  {/* BLock */}
                    <div className={
                      transactionData?.paymentStatus === 'SUCCESS' ? "pg_status_background_success"
                        : (transactionData?.paymentStatus === 'FAILED' || transactionData?.paymentStatus === 'FAILURE') ? 'pg_status_background_failed'
                          : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? 'pg_status_background_failed'
                            : 'pg_status_background_pending'
                    }>
                      {/* Status Icon */}
                      {/* <img className="pg_status_icon" src={
                            transactionData?.paymentStatus === 'SUCCESS' ? 'Success'
                                : (transactionData?.paymentStatus === 'FAILED' || transactionData?.paymentStatus === 'FAILURE') ? 'Fail'
                                    : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? 'Fail'
                                        : 'Pending'
                        } /> */}

                      {/* Title */}
                      <div className="pg_status_title">
                        {transactionData?.paymentStatus === 'SUCCESS' ? 'Success!'
                          : (transactionData?.paymentStatus === 'FAILED' || transactionData?.paymentStatus === 'FAILURE') ? 'Failure!'
                            : transactionData?.paymentStatus === 'DECLINED' || transactionData?.paymentStatus === 'CANCELLED' ? 'User Cancelled'
                              : 'Processing...'}
                      </div>
                       {/* Currency */}
                      {
                        !loading && <>
                          <div className="pg_status_amount">
                            {getSymbolFromCurrency("INR")} {" "}
                            {transactionData?.amount}
                          </div>


                          <div className="pg_status_date">{new Date(transactionData?.paymentTimestamp).toLocaleString()}</div>

                          <div className="pg_status_transaction">{t('premium.transaction_id')} : {transactionData?.transactionId}</div>
                        </>
                      }

                      {transactionData?.paymentStatus === 'SUCCESS' ?
                        <div className="pg_status_transaction">
                          {message}
                        </div>
                        :
                        (transactionData?.paymentStatus === 'FAILED' || transactionData?.paymentStatus === 'FAILURE') ?
                          <div className="pg_status_transaction">{t('premium.cant_proceed')}</div>: t('premium.transaction_inprogess')
                      }
                      <div className="pg_status_transaction">{transactionData?.message}</div>
                    </div>
                    <button className={`btn btn-primary main-btn next mt-4 mb-4 ml-2 ${
                    transactionData?.paymentStatus !== 'SUCCESS' && 
                    (transactionData?.paymentStatus === 'FAILED' || transactionData?.paymentStatus === 'FAILURE') ? 
                    "disabled" : 
                    ""}`} 
                    style={{ width: "100%", border: '1px solid' }} 
                    onClick={handlerDone}>

                    {t('premium.done')}

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default PayuPaymentStatus;
