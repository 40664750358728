
import { func } from "./logFunc";
import { routesBackend } from "./routesBackend";
import { url } from "./url";
import Cookies from 'js-cookie'
import * as jose from "jose";
import axios from "axios";

var header = localStorage.getItem('token') ? {'x-access-token':  localStorage.getItem('token') } : {"user-type": "appUser"};
// header['company-code'] = 'GJ';
// header['language'] = localStorage.getItem("GJLangCode")?.toUpperCase() || Cookies.get("GJLangCode")?.toUpperCase()  ||  "EN"
let countryId = localStorage.getItem('country') ? localStorage.getItem('country') : JSON.parse(localStorage.getItem("profile"))?.country?._id;
let stringWithoutQuotes = countryId?.replace(/"/g, '');
// if(stringWithoutQuotes && stringWithoutQuotes != undefined && stringWithoutQuotes != "undefined" && stringWithoutQuotes != null && stringWithoutQuotes != "null" && stringWithoutQuotes != ""){
//     // header['country'] = stringWithoutQuotes
// }
const headers = {
    "user-type": "appUser",
    'company-code':'GJ',
    'language':localStorage.getItem("GJLangCode")?.toUpperCase() || Cookies.get("GJLangCode")?.toUpperCase()  ||  "EN",
    'country':stringWithoutQuotes
}
const secret = new TextEncoder().encode(process.env.REACT_APP_SECRET_KEY);
async function newToken(){
    const jwt = await new jose.SignJWT(headers)
    .setProtectedHeader({ alg: "HS256" })
    .setExpirationTime(Math.floor(Date.now() / 1000) + 8)
    .sign(secret);
     header['credentials'] = jwt;
}

// console.log("header", header)

export const collectDailyRewards = async (data) => {
    await newToken()
    return axios
      .post(`${url.apiUrl}${routesBackend.collectDailyLoginReward}`, data, {
        headers:header
      })
      .then((res) => {
        return res;
      });
  };

export const addTicket = async (data) => {
    await newToken()
    return axios
      .post(`${url.apiUrl}${routesBackend.ticketCreate}`, data, {
        headers:header
      })
      .then((res) => {
        return res;
      });
  };

  export const requestUpdateProfile = async(data) => {
    await newToken()
    return axios
      .post(`${url.apiUrl}${routesBackend.createProfile}`, data, {
        headers:header
      })
      .then((res) => {
        console.log(res)
        return res;
      })
      .catch((err) => {
        console.log(err)
        return err;
      });
  };
  