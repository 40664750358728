import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { func } from "../../../Utilities/logFunc";
import { getTransactionList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Loader from "../../Common/Loader";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const DiamondTransaction = ({ type }) => {
  let [dataCoin, setLeaderBoardData] = useState([]);
  let [limit, setLimit] = useState(10);
  let [count, setCount] = useState(1);
  let [search, setSearch] = useState("");
  let [totalRecord, setTotalRecord] = useState(1);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.My_Reward_Tab);
  }
  const fetchLeaderBoardData = async (totalCount) => {
    try {
      if (type === "diamond") {
        setLoading(true);
        let data = {
          currencyType: "diamond",
          skip: dataCoin.length,
          limit: 10
        };

        const res = await getTransactionList(data);
        if (res?.data?.list) {
          setLeaderBoardData([...dataCoin, ...res?.data?.list]);
          setTotalRecord(res?.data?.count);
          setLoading(false);
        }
        func(res);
      }
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg)
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!dataCoin.length > 0) {
      fetchLeaderBoardData(count);
    }
  }, [type]);

  const loadMoreBtnClick = () => {
    // console.log("clicked",totalCount)
    let totalCount = parseInt(count) + 1;
    setCount(totalCount);
    fetchLeaderBoardData(totalCount);
  };

  useEffect(() => {
    fetchLeaderBoardData(count);
  }, [search]);

  return (
    <div class="row date-container">
      <form class="col-xl-12 col-lg-12">
        <div
          class="accordion transactionaccordion d-grid"
          id="accordionPanelsStayOpenExample"
        >
          {dataCoin.length > 0 ? (
            dataCoin.map((value, i) => {
              let previousItem = i != 0 && dataCoin[i - 1];
              let currentDate = moment(value.createdAt).format("Do MMMM YYYY");
              let previousDate = previousItem
                ? moment(previousItem.createdAt).format("Do MMMM YYYY")
                : "";

              return (
                <>
                  {previousItem && currentDate != previousDate && (
                    <h6 class="text-center text-dark mt-3">{currentDate}</h6>
                  )}
                  {i == 0 && (
                    <h6 class="text-center text-dark mt-3">{currentDate}</h6>
                  )}

                  <div class="accordion-item transactionaccordion-item">
                    <h5 class="accordion-header">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#panelsStayOpen-collapseOne${i}`}
                        aria-expanded="true"
                        aria-controls={`panelsStayOpen-collapseOne${i}`}
                      >
                        <h6 class="mb-1 h5   d-flex justify-content-center align-items-center">
                          <span className="d-flex align-items-center" style={{minWidth:'10rem', maxWidth:'15rem'}} >
                          <img
                            src="/assets/images/transaction.png"
                            class="me-1"
                            alt="transaction"
                          />{"  "}
                          {String(value?.transactionType) === "1" && value?.amount != 0 && "+"}
                          {String(value?.transactionType) === "2" && value?.amount != 0 && "-"}
                          {"  "}
                          {value?.paymentTypeId === 2 && value?.amount === 0
                            ? " Free"
                            : value?.amount}
                          <span class="mx-2 d-flex  align-items-center">
                            <img
                              src={
                                url?.imageUrl + value?.currency?.img?.default
                              }
                              class="me-1 icon-18"
                              alt="transaction"
                            />
                          </span>
                          </span>
                          {" "}
                          <span class="" >
                            {value?.paymentType}
                          </span>
                        </h6>
                      </button>
                    </h5>
                    <div
                      id={`panelsStayOpen-collapseOne${i}`}
                      class="accordion-collapse collapse"
                    >
                      <div class="accordion-body-diamond">
                        <div
                          class="mt-4 acc-body-diamon"
                          // style={{ marginTop: "2.5rem!important" }}
                        >
                          <h5 class="bodyBoldFont h5">
                            <span>{t("coin_store.transaction_id")}  : </span>
                            {value?.transactionUniqueID}
                          </h5>
                          <h5 class="bodyBoldFont h5">
                            <span>{t("coin_store.transaction_date")}  : </span>
                            {moment(value?.createdAt).format(
                              "DD MMMM YYYY hh:mm A"
                            )}
                          </h5>
                          {value?.contestDetails?.game && (
                            <h5 class="bodyBoldFont h5">
                              <span>{t("coin_store.game")} : </span>
                              {value?.contestDetails?.game?.name} -{" "}
                              {value?.contestDetails?.gameType?.name}
                            </h5>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p className="text-center">{t("notFound.no_transaction_found")}</p>
          )}
          {dataCoin.length < totalRecord && (
            <div class="card-footer d-flex justify-content-center mt-5">
              <div
                class="btn btn-primary text-center"
                onClick={() => {
                  loadMoreBtnClick()}}
              >
                {t("contest.load_more")}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default DiamondTransaction;
