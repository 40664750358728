import React, { useState, useEffect } from "react";
import {
  getCountryList,
  getAccountProfile,
  getUserInGameName,
  gamerjiTournamentJoin,
  requestContestJoin,
  setShowPremiumPopupContest,
  userInGameName,
  getHeaderApi,
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import { url } from "../../Utilities/url";
import { Formik, Form, Field } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { json, useNavigate } from "react-router";
import { errors } from "../../Utilities/error";
import Loader from "./Loader";
import Error from "./Error";
import { useProfileDataContext } from "../HomePage/ProfileContext";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../../Utilities/validateEmail";

const TeamRegistration = ({
  postData,
  type,
  noOfPlayer,
  setShowTeamRegisterModal,
  setShowTournamentConfirmationModal,
  game,
  setShowPremiumPopupTournament,
  userInGameName,
  setShowJVC
}) => {
  // console.log("teamm registration contes")

  const [countryList, setCountryList] = useState([]);
  const [userDataInfo, setUserData] = useState("");
  const [teamName, setTeamName] = useState("");
  const [err, setErr] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [activeTab, setActiveTab] = useState({})
  const [captainInfo, setCaptainInfo] = useState({
    gamerjiName: "",
    countryCode: "",
    phoneNo: "",
    email:""
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  let newArray = [];
  const [disabledCards, setDisabledCards] = useState([]);
  const [contestDisabled, setContestDisabled] = useState(false);

  for (let i = 0; i < noOfPlayer - 1; i++) {
    newArray.push({
      type: "phoneNo",
      countryCode: "",
      phoneNo: "",
      inNameGame: "",
      gamerjiName: "",
      isSubmit: false,
      isOptional: false,
      userId: "",
      levelImage: "",
    });
  }

  newArray.push({
    type: "phoneNo",
    countryCode: "",
    phoneNo: "",
    inNameGame: "",
    gamerjiName: "",
    isSubmit: false,
    isOptional: true,
    userId: "",
    levelImage: "",
  });

  const [frmInitialValues, setFrmInitialValues] = useState(newArray);
  const [isLoading, setLoading] = useState(false);
  const { liteProfile, updateProfileData } = useProfileDataContext();

  const fetchCountry = async () => {
    try {
      const res = await getCountryList();
      setCountryList((prev) => [...res?.list]);
    } catch (error) {
      func("error", error);
    }
  };

  const getUserInGameNameData = async (data, index) => {
    // setLoading(true)
    try {
      const res = await getUserInGameName(data);
      if (res?.data?.data) {
        let formdata = [...frmInitialValues];
        let fromdataobj = { ...formdata[index] };
        fromdataobj.inNameGame = res?.data?.data?.[0]?.userINGameName;
        fromdataobj.gamerjiName = res?.data?.data?.[0]?.gamerjiName;
        fromdataobj.isSubmit = true;
        fromdataobj.userId = res?.data?.data?.[0]?.user;
        fromdataobj.userLevel =
          res?.data?.data?.[0]?.userLevel?.level?.featuredImage?.default;
        formdata[index] = fromdataobj;
        setFrmInitialValues(formdata);
        setDisabledCards(prevState => ([...prevState, index]));
        // setLoading(false)
      }
      else {
        toast.error(res?.response?.data?.errors?.[0]?.msg);
      }
      func(res);
    } catch (error) {
      // setLoading(false)

      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    // fetchCountry();
    // getAccountProfileDetails();
    var user_data = JSON.parse(localStorage.getItem("profile"));
    setUserData(user_data);
  }, []);

  useEffect(() => {
    let formdata = { ...captainInfo };
    formdata.gamerjiName = userDataInfo?.item?.gamerjiName;
    formdata.phoneNo = userDataInfo?.item?.phone;
    formdata.email = userDataInfo?.item?.email

    // let newArray = countryList.filter(function (el) {
    //   return el?.dialingCode === userDataInfo?.item?.phoneCode;
    // });

    // if (newArray?.length && newArray?.length > 0) {
      
    // }
    formdata.countryCode = userDataInfo?.item?.phoneCode;

    setCaptainInfo(formdata);

    let playerArray = [...frmInitialValues];
    let newArrayInfo = [];
    for (let i = 0; i < playerArray.length; i++) {
      playerArray[i].countryCode = formdata.countryCode;
      newArrayInfo.push(playerArray[i]);
    }
    console.log(newArrayInfo)
    setFrmInitialValues(newArrayInfo);
  }, [userDataInfo]);

  const handleUsrBtn = async (index) => {
    setErr('')
    let formdata = [...frmInitialValues];
    let fromdataobj = { ...formdata[index] };
    console.log("datatatattata", fromdataobj, captainInfo);
    if(fromdataobj?.type == "email"){
      let res = validateEmail(fromdataobj?.phoneNo)
      console.log("***********", res)
      if(!res) {
        setErr(t("enter_valid_email"));
        return
      }
    }
    if(fromdataobj?.type == "email" && fromdataobj?.email == "") {
      setErr(t("enter_valid_email"));
      return;
    }
    else if (fromdataobj?.phoneNo == "") {
      setErr(t("error_Please_enter_the_phone_number"));
      return;
    }
   
  
    if ( captainInfo?.email == fromdataobj?.phoneNo ||  captainInfo?.phoneNo == fromdataobj?.phoneNo  ) {
      setErr(t("error_enter_other_than_yours"));
      return;
    }

    console.log(fromdataobj?.phoneNo, fromdataobj?.countryCode, game);
    let checkTeam = checkMobileWithTeam(fromdataobj?.phoneNo, index);
    if (!checkTeam) {
      let data = {
        search: {
          phone: fromdataobj?.phoneNo,
          phoneCode: fromdataobj?.countryCode,
          game: game,
        },
      };
      getUserInGameNameData(data, index);
    } else {
      setErr(t("error_enter_already_added"));
      return;
    }
  };

  let checkMobileWithTeam = (number, ind) => {
    let index = frmInitialValues.findIndex(
      (obj, i) => obj?.phoneNo == number && i !== ind
    );
    let indexCap = frmInitialValues.findIndex(
      (obj) => obj?.phoneNo == captainInfo.phoneNo
    );
    if (index > -1 || indexCap > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleUsrBtnRemove = async (index) => {
    setErr('')
    let formdata = [...frmInitialValues];
    let fromdataobj = { ...formdata[index] };
    fromdataobj.inNameGame = "";
    fromdataobj.gamerjiName = "";
    fromdataobj.userId = "";
    fromdataobj.userLevel = "";
    fromdataobj.phoneNo = "";
    fromdataobj.phoneNo = "";
    fromdataobj.isSubmit = false;
    formdata[index] = fromdataobj;
    setFrmInitialValues(formdata);
    setDisabledCards(prevState => (prevState.filter(
      (cardIndex) => cardIndex !== index
    )));
  };

  const handleSubmit = async (e) => {
    // setShowTournamentConfirmationModal(false)
    e.preventDefault();
    let errMsg = "";

    if (!teamName) {
      errMsg += `Please enter team name`;
      toast.error(t('error_team_name_is_required'));
      return;
    }

    frmInitialValues.forEach((euser, index) => {
      index = index + 1;
      if (!euser?.isOptional) {
        if (!euser.phoneNo) {
          errMsg += `${t("error_Please_enter_the_phone_number")} ${index + 1}`;
          toast.error(
            `${t("error_Please_enter_the_phone_number")} ${index + 1}`
          );
          return;
        }
        if (!euser.isSubmit) {
          errMsg += `Please add user ${index + 1}`;
          toast.error(`${t("please_add_user")} ${index + 1}`);
          return;
        }
        if (!euser.gamerjiName) {
          errMsg += `${t("error_enter_gamerji_username")} ${index + 1}`;
          toast.error(`${t("error_enter_gamerji_username")} ${index + 1}`);
          return;
        }
        if (!euser.inNameGame) {
          errMsg += `${t("success_Please_enter_Gamerji_username")} ${
            index + 1
          }`;
          toast.error(
            `${t("success_Please_enter_Gamerji_username")} ${index + 1}`
          );
          return;
        }
      } else {
        if (euser.phoneNo != "") {
          if (!euser.phoneNo) {
            errMsg += `${t("error_Please_enter_the_phone_number")} ${
              index + 1
            }`;
            toast.error(
              `${t("error_Please_enter_the_phone_number")} ${index + 1}`
            );
            return;
          }
          if (!euser.isSubmit) {
            errMsg += `Please add user ${index + 1}`;
            toast.error(`Please add user ${index + 1}`);
            return;
          }
          if (!euser.gamerjiName) {
            errMsg += `${t("error_enter_gamerji_username")} ${index + 1}`;
            toast.error(`${t("error_enter_gamerji_username")} ${index + 1}`);
            return;
          }
          if (!euser.inNameGame) {
            errMsg += `${t("success_Please_enter_Gamerji_username")} ${
              index + 1
            }`;
            toast.error(
              `${t("success_Please_enter_Gamerji_username")} ${index + 1}`
            );
            return;
          }
        }
      }
    });

    if (errMsg) {
      return false;
    } else {
      setContestDisabled(true)
      let data = postData;
      func("postdata 260", postData);
      let players = [];
      frmInitialValues.forEach((euser, index) => {
        if (euser?.phoneNo) {
          let country_id = "";
          let newArray = countryList.filter(function (el) {
            return el?.dialingCode === euser?.countryCode;
          });

          if (newArray?.length && newArray?.length > 0) {
            country_id = newArray[0]?._id;
          }

          players.push({
            phone: euser?.phoneNo,
            in_game_name: euser?.inNameGame,
            user_id: euser?.userId,
            gamerjiName: euser?.gamerjiName,
            email: euser?.email
            // levelImage: euser?.userLevel,
            // country: country_id,
          });
        }
      });

      if (type === "event") {
        data["team"] = players;
        data["teamName"] = teamName;
        setLoading(true);

        try {
          const res = await gamerjiTournamentJoin(data);
          if (res?.data?.success) {
            setContestDisabled(false)
            setLoading(false);
            const resProfile = await getHeaderApi();
            if (resProfile?.data) updateProfileData(resProfile?.data);
            setShowTeamRegisterModal(false);
            setShowTournamentConfirmationModal(false);
            navigate("/home/tournament-details/" + data?.event);
          } else {
            setLoading(false);
            console.log("********",res?.response?.data?.errors?.[0]?.msg)
            if (
              res?.data?.errors?.[0]?.data ==
              "ALREADY_JOINED_MAX_FREE_TRANSACTION"
            ) {
            setContestDisabled(false)

              setShowTeamRegisterModal((prev) => false);
              setShowTournamentConfirmationModal((prev) => false);
              setShowPremiumPopupTournament(true);
            }
            else if (
              res?.data?.errors?.[0]?.data ==
              "events.validation.MAX_FREE_TRANSACTION"
            ) {
            setContestDisabled(false)
            setShowTeamRegisterModal((prev) => false);
            setShowTournamentConfirmationModal((prev) => false);
              setShowPremiumPopupTournament(true);
            } 
            else if ( res?.response?.data?.errors?.[0]?.msg == "ALREADY_JOINED_MAX_FREE_TRANSACTION" ||  res?.response?.data?.errors?.[0]?.msg == "events.validation.MAX_FREE_TRANSACTION"){
              setContestDisabled(false)
              setShowTeamRegisterModal((prev) => false);
              setShowTournamentConfirmationModal((prev) => false);
              setShowPremiumPopupTournament(true);

            }
            else if ( res?.response?.data?.errors?.[0]?.msg){
              toast.error(res?.response?.data?.errors?.[0]?.msg)
            }
            else {
              console.log("*************")
              toast.error(res?.data?.errors?.[0]?.msg);
              setShowPremiumPopupTournament(true);
            }
            // toast.error(res?.data?.errors?.[0]?.msg);
              if(setShowJVC) setShowJVC(false)
              
          }
          func(res);
        } catch (error) {
          console.log("catrchhhh")
          setContestDisabled(false)
          setLoading(false);
          if (
            error?.response?.data?.errors?.[0]?.data ==
            "ALREADY_JOINED_MAX_FREE_TRANSACTION"
          ) {
            setShowPremiumPopupTournament(true);
            setShowTeamRegisterModal((prev) => false);
            setShowTournamentConfirmationModal((prev) => false);
          } else {
            toast.error(error?.response?.data?.errors?.[0]?.msg);
          }
          if(setShowJVC) setShowJVC(false)
        }
      } else if (type === "contest") {
        data["players_data"] = players;
        data["teamName"] = teamName;
        setLoading(true);

        try {
          const res = await requestContestJoin(data);
          if (res?.data?.success) {
            setContestDisabled(false)
            setLoading(false);
            const resProfile = await getHeaderApi();

            console.log(resProfile?.data);
            if (resProfile?.data) updateProfileData(resProfile?.data);
            func("requestContestJoin", res?.data.data[0].param.item._id);
            setShowTournamentConfirmationModal(false);
            setShowTeamRegisterModal((prev) => false);

            navigate("/home/contest-details/" + data?.contest);
          }
          else {
            setContestDisabled(false)
            setLoading(false)
            setShowTeamRegisterModal((prev) => false);
            setShowTournamentConfirmationModal((prev) => false);
            if (
              res?.response?.data?.errors?.[0]?.data ==
              "ALREADY_JOINED_MAX_FREE_TRANSACTION"
            ) {
              setShowPremiumPopupTournament(true);
            }
            else {
            toast.error(res?.response?.data?.errors?.[0]?.msg);
            }
          }
        } catch (error) {
          setContestDisabled(false)
          setLoading(false, error);
          if (
            error?.response?.data?.errors?.[0]?.data ==
            "ALREADY_JOINED_MAX_FREE_TRANSACTION"
          ) {
            setShowPremiumPopupTournament(true);
          }

          if (error?.response?.data?.errors)
            toast.error(error?.response?.data?.errors?.[0]?.msg);
          else toast.error(error?.response?.data?.Error?.[0]?.msg);

          // setShowTeamRegisterModal((prev) => false);
          // setShowTournamentConfirmationModal((prev) => false);

          // toast.error(error);
        }
      }
    }
  };
 // if (isLoading) return <Loader />;

  return (
    <div class="row justify-content-center">
      <div class="col-lg-11 col-12 px-lg-4">
        <div class="title">
          <h3 class="text-center bodyBoldFont mb-4 pb-3 text-dark">
            {t("team_register.team_registeration")}
          </h3>
        </div>
        <div class="content">
          <div class="row justify-content-center mt-4 pt-2 text-dark">
            <div class="col-lg-7 col-md-9 col-12 px-lg-2">
              <Formik
                enableReinitialize={true}
                initialValues={frmInitialValues}
                // onSubmit={(values) => {
                // }}
              >
                {({ errors, values }) => (
                  <div>
                    <div class="mb-4">
                      <label
                        for="exampleInputEmail1"
                        class="form-label h5 mb-1"
                      >
                         {t("team_register.team_name")}
                      </label>
                      <input
                        type="email"
                        class="form-control form-control-sm"
                        id="exampleInputEmail1"
                        placeholder={t("team_register.enter_team_name")}
                        value={teamName}
                        onChange={(e) => setTeamName(e.target.value)}
                      />
                    </div>
                    <div class="card addPlayerCard mb-4 text-white">
                      <div
                        class="card-header d-flex align-items-center justify-content-between"
                        style={{ position: "relative" }}
                      >
                        <div class="playerDesignation">
                          {t("team_register.captain")}
                        </div>
                        <h4
                          // class="bodyBoldFont mb-0 px-2 d11-ticket-text"
                          class="bodyBoldFont mb-0 px-10 text-white"
                          // style={{
                          //   width: "50%",
                          //   position: "absolute",
                          //   right: 0,
                          // }}
                        >
                          {captainInfo?.gamerjiName}
                        </h4>
                      </div>
                      <div class="card-body">
                        <div class="row align-items-center mb-2">
                          <div class="col-5">
                            <div class="input-group w-auto">
                              <span
                                class="input-group-text rounded-start-5 bg-light-blue border-0 pe-0"
                                id="basic-addon1"
                              >
                              {liteProfile?.item?.isMobileVerified  ? 
                                  <img
                                  className="icon-24"
                                  src={
                                    url.imageUrl +
                                    liteProfile?.country?.flag?.default
                                  }
                                  alt=""
                                />
                                : ""}
                                
                               
                              </span>
                              <div
                                name=""
                                id=""
                                class="form-control form-control-sm bg-light-blue ps-2 d-flex text-light"
                                style={{
                                  maxWidth: "70px",
                                  alignItems: "center",
                                  border:'none',
                                  outline:'none'
                                }}
                                value={captainInfo?.countryCode}
                                disabled={true}
                              >

                                { liteProfile?.item?.isMobileVerified ? liteProfile?.country?.dialingCode : "Email"}
                                {/* {countryList?.length > 0 ? (
                                  countryList?.map((ele, index) => (
                                    <option>
                                      <img
                                        className="country_img"
                                        src={url.imageUrl + ele?.flag?.default}
                                        alt={ele?.name}
                                      />
                                      {ele?.dialingCode}
                                    </option>
                                  ))
                                ) : (
                                  <></>
                                )} */}
                              </div>
                            </div>
                          </div>
                          <div
                            class="col ps-0 input-group text-light"
                            // class="col-lg ps-0 input-group"
                            // style={{ position: "relative" }}
                          >
                            {/* {console.log(captainInfo)} */}
                            <input
                              type="text"
                              class="form-control form-control-sm bg-light-blue text-light"
                              placeholder={t(
                                "team_register.enter_mobile_number"
                              )}
                              value={liteProfile?.item?.isMobileVerified ? captainInfo?.phoneNo : captainInfo?.email}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <div class="row align-items-center mb-2">
                          <div class="col-5">
                            <h5 class="mb-0">
                              {t("team_register.in_game_name")}:
                            </h5>
                          </div>
                          <div class="col ps-0 text-light">
                            <input
                              type="text"
                              class="form-control form-control-sm bg-light-blue text-light"
                              placeholder={t("team_register.enter_name")}
                              value={userInGameName}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {frmInitialValues?.length > 0 &&
                      frmInitialValues?.map((ele, index) => {
                        const cardId = `card-${index}`;
                        const activeTabs = activeTab[cardId] || "Mobile";

                        return(
                        <>
                          <div class="card addPlayerCard mb-4">
                            <div
                              class="card-header d-flex align-items-center justify-content-between"
                              style={{ position: "relative" }}
                            >
                              <div class="playerDesignation" style={{width:'105px'}}>
                                <p className="mb-0">{t("team_register.player", { n: index + 2 })}{" "}</p>
                                {ele?.isOptional && (<p className="mb-0" style={{fontSize:'0.8rem'}}>{t("team_register.optional")}</p>)}
                              </div>
                              <div>
                              <h4
                                class="mb-0 ms-4 d-flex px-3"
                                // style={{width:'50%', position:'absolute',right:0}}
                              >
                                <div >
                                <input
                                        class="form-check-input mt-1"
                                        type="radio"
                                        name={`flexRadioDefault${index+'m'}`}
                                        id={`flexRadioDefault${index+'m'}`}
                                        // disabled={false}
                                        // value={index+5}
                                        // checked={activeTab == index+'m'}
                                        disabled={disabledCards.includes(index)}
                                        value="Mobile"
                                        checked={activeTabs === "Mobile"}
                                        onChange={(e)=> {
                                          setErr('')
                                          
                                          let formdata = [...frmInitialValues];
                                          let fromdataobj = {
                                            ...formdata[index],
                                          };
                                          fromdataobj.type = 'phoneNo';
                                          fromdataobj.phoneNo = '';
                                          formdata[index] = fromdataobj;
                                          setFrmInitialValues(formdata);
                                          setMobileNo()
                                          // setActiveTab(index+'m')}
                                          setActiveTab((prevTabs) => ({
                                            ...prevTabs,
                                            [cardId]: e.target.value,
                                          }));
                                        }}

                                        // onChange={(e) => {
                                        //   func("tou Modal 155", contestData);
                                        //   func("tou Modal 156", e.target.value);
                                        //   setActiveId(contestData?._id);
                                        //   onSelectTime(contestData, e);
                                        // }}
                                      />
                                  <label
                                    class="form-check-label px-2 text-light"
                                    style={{lineHeight:'2rem'}}
                                    for={`phoneNo${index+'m'}`}
                                  >
                                    {t("team_register.via_mobile")}
                                  </label>
                                  </div>
                                  <div >
                                  <input
                                        class="form-check-input mt-1"
                                        type="radio"
                                        name={`flexRadioDefault${index+'e'}`}
                                        id={`flexRadioDefault${index+'e'}`}
                                        // disabled={false}
                                        // value={index+6}
                                        // checked={activeTab == index+'e'}
                                        disabled={disabledCards.includes(index)}
                                        value="Email"
                                        checked={activeTabs === "Email"}
                                        onChange={(e)=> {
                                          setErr('')
                                          let formdata = [...frmInitialValues];
                                          let fromdataobj = {
                                            ...formdata[index],
                                          };
                                          fromdataobj.type = 'email';
                                          fromdataobj.phoneNo = '';
                                          formdata[index] = fromdataobj;
                                          setFrmInitialValues(formdata);
                                          console.log(ele?.type, frmInitialValues[index])
                                          setMobileNo('')

                                          setActiveTab(index+'e')

                                          setActiveTab((prevTabs) => ({
                                            ...prevTabs,
                                            [cardId]: e.target.value,
                                          }));
                                        
                                        }}
                                        // onChange={(e) => {
                                        //   func("tou Modal 155", contestData);
                                        //   func("tou Modal 156", e.target.value);
                                        //   setActiveId(contestData?._id);
                                        //   onSelectTime(contestData, e);
                                        // }}
                                      />
                                  <label
                                    class="form-check-label px-2 text-light"
                                    for={`phoneNo${index+'e'}`}
                                    style={{lineHeight:'2rem'}}

                                  >
                                    {t("team_register.via_email")}
                                  </label>
                                </div>
                              </h4>
                              </div>
                            </div>
                            <div class="card-body">
                              <div class="row align-items-center mb-2">
                                <div class="col-5">
                                  {activeTabs === "Email" ? (
                                    <h5 class="mb-0">
                                      {t("crm.email")}
                                    </h5>
                                  )
                                  :
                                  (
                                    <div class="input-group w-auto">
                                      <span
                                        class="input-group-text rounded-start-5 bg-light-blue border-0 pe-0"
                                        id="basic-addon1"
                                      >
                                        <img
                                          className="icon-24"
                                          src={
                                            url.imageUrl +
                                            liteProfile?.country?.flag?.default
                                          }
                                          alt=""
                                        />
                                      </span>
                                      <div
                                        name=""
                                        id=""
                                        class="form-control form-control-sm bg-light-blue ps-2 d-flex text-light"
                                        style={{
                                          maxWidth: "70px",
                                          alignItems: "center",
                                          border:'none',
                                          outline:'none'
                                        }}
                                        value={captainInfo?.countryCode}
                                        disabled={true}
                                      >
                                        {/* {JSON.parse(localStorage.getItem("profile"))?.country?.dialingCode} */}
                                        {liteProfile?.country?.dialingCode}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {/* {console.log(ele?.type,t(
                                        "team_register.enter_mobile_number"
                                      ))} */}
                                <div
                                  class="col ps-0 input-group text-light"
                                  // style={{position:"relative"}}
                                >
                                  {activeTabs === "Email" ? (
                                    <input
                                      type="email"
                                      class="form-control form-control-sm bg-light-blue text-light form-placeholder"
                                      placeholder={
                                         t("team_register.enter_email")
                                          
                                      }
                                      value={frmInitialValues[index]?.phoneNo}
                                      readOnly={ele?.isSubmit && true}
                                      onChange={(e) => {
                                        let formdata = [...frmInitialValues];
                                        let fromdataobj = { ...formdata[index] };
                                        fromdataobj.phoneNo = e.target.value;
                                        formdata[index] = fromdataobj;
                                        setFrmInitialValues(formdata);
                                        setMobileNo(e.target.value);
                                      }}
                                    />
                                  ) : (
                                    <input
                                      type="number"
                                      class="form-control form-control-sm bg-light-blue text-light form-placeholder"
                                      placeholder={t(
                                        "team_register.enter_mobile_number"
                                      )}
                                      readOnly={ele?.isSubmit && true}
                                      // onKeyDown={(e) => {
                                      //   if (new RegExp(/[0-9]/).test(e.key)) {
                                      //   } else e.preventDefault();
                                      // }}
                                      onKeyDown={(e) =>
                                        ["e", "E", "+", "-","."].includes(e.key) &&
                                        e.preventDefault()
                                      }
                                      value={frmInitialValues[
                                        index
                                      ]?.phoneNo.slice(0, 14)}
                                      onChange={(e) => {
                                        let formdata = [...frmInitialValues];
                                        let fromdataobj = {
                                          ...formdata[index],
                                        };
                                        fromdataobj.phoneNo = e.target.value;
                                        formdata[index] = fromdataobj;
                                        setFrmInitialValues(formdata);
                                      }}
                                      // style={{paddingRight:'70px'}}
                                    />
                                  )}
                                  {ele?.isSubmit ? (
                                    <button
                                      class="btn bg-dark btn-sm rounded-5"
                                      type="button"
                                      onClick={() => handleUsrBtnRemove(index)}
                                      // style={{position:'absolute',right:'4.3%',top:'0',height:'100%',lineHeight:'1'}}
                                    >
                                      {t("team_register.remove")}
                                    </button>
                                  ) : (
                                    <button
                                      class="btn bg-dark btn-sm rounded-5 "
                                      // style={{position:'absolute',right:'4.3%',top:'0',height:'100%',lineHeight:'1'}}
                                      type="button"
                                      onClick={() => handleUsrBtn(index)}
                                    >
                                      {t("team_register.add")}
                                    </button>
                                  )}
                                </div>
                              </div>
                              <div class="row align-items-center mb-2">
                                <div class="col-5">
                                  <h5 class="mb-0">
                                    {t("team_register.in_game_name")}:
                                  </h5>
                                </div>
                                <div class="col ps-0">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm bg-light-blue text-light form-placeholder"
                                    placeholder={t("team_register.enter_name")}
                                    value={frmInitialValues[index]?.inNameGame}
                                    readOnly={ele?.isSubmit && true}
                                    onChange={(e) => {
                                      let formdata = [...frmInitialValues];
                                      let fromdataobj = { ...formdata[index] };
                                      fromdataobj.inNameGame = e.target.value;
                                      formdata[index] = fromdataobj;
                                      setFrmInitialValues(formdata);
                                    }}
                                  />
                                </div>
                              </div>
                              <div class="row align-items-center">
                                <div class="col-5">
                                  <h5 class="mb-0">
                                    {t("team_register.gamerji_name")}:
                                  </h5>
                                </div>
                                <div class="col ps-0">
                                  <input
                                    type="text"
                                    class="form-control form-control-sm bg-light-blue text-light form-placeholder"
                                    placeholder={t("team_register.enter_name")}
                                    value={frmInitialValues[index]?.gamerjiName}
                                    readOnly={ele?.isSubmit && true}
                                    onChange={(e) => {
                                      let formdata = [...frmInitialValues];
                                      let fromdataobj = { ...formdata[index] };
                                      fromdataobj.gamerjiName = e.target.value;
                                      formdata[index] = fromdataobj;
                                      setFrmInitialValues(formdata);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )})}
                    {err && <Error error={err} />}
                    <div class="d-grid mt-3 pt-1">
                      <button
                        type="button"
                        class="btn bg-primary-color-dark-blue btn-arrow text-light"
                        disabled={contestDisabled}
                        onClick={(e) => handleSubmit(e)}
                      >
                        {t("team_register.save_team")}
                      </button>
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {isLoading ?  <Loader /> : <></>}
    </div>
  );
};

export default TeamRegistration;